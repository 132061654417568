<template>
    <div>
        <!--<PageHeader :titulo="titulo"/>--> 
        <titulo-seccion>
            <template slot="area-rutas">
                <router-link to="/">Inicio</router-link> / 
                <router-link to="/videos">Videos</router-link> / 
                <router-link to="/videos/playlists">Playlists</router-link> /
                <span class="active">{{titulo}}</span>
            </template>
            <template slot="area-titulo">
                <h3 class="text-center">{{titulo}}</h3>
            </template>
        </titulo-seccion> 
        <section class="noticias">
            <div class="container">
                <div class="row my-4">
                    <div v-for="(item, index) in items" :key="index" class="col-md-3 col-sm-2 col-xs-12">
                        <div class="card card-video mb-4">
                            <div class="video-portada">
                                <img 
                                    v-lazy="item.thumbnail" 
                                    @click="selectVideo(item)" 
                                    class="img-fluid img-responsive cursor md-round" 
                                    :alt="item.title" 
                                    :title="item.title"
                                    v-b-modal.modal-video
                                > 
                                <a @click="selectVideo(item)" v-b-modal.modal-video class="btn btn-icon btn-play">
                                    <i class="icon icon-white">
                                    <svg width="10" height="10" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0V24L20 12L0 0Z" fill="white"/>
                                    </svg>
                                    </i>
                                </a>
                                </div>                            
                            <div class="card-body p-0 pt-3">
                                <p class="card-title"><strong class="card-title">{{item.title}}</strong></p>
                                <p class="card-text text-truncate">{{item.description}}</p>
                                <div class="d-flex justify-content-between align-items-center text-start">
                                    <small class="text-start text-muted">{{item.published_at}}</small>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
            </div>
        </section>
        <!-- modal video -->
        <b-modal
            id="modal-video"
            ref="my-modal"
            header-bg-variant="dark"
            header-text-variant='light'
            header-class="text-small"
            title-class='text-truncate'
            size="lg"
            body-class="p-0"
            centered
            :hide-footer="true"
        >
        <template #modal-title>
          <h6>{{title}}</h6>
        </template>
            <b-embed 
                v-if="video"
                class="border"
                type="iframe" 
                :allowfullscreen="true" 
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                :src="video"
            />
        </b-modal>
    </div>
</template>

<script>
import axiosIns from '@/libs/axios'
//import PageHeader from '/src/components/PageHeader.vue';
import {BModal,VBModal,BEmbed  } from "bootstrap-vue";
import TituloSeccion from '@/views/secciones/TituloSeccion.vue'
export default {
    components:{
        //PageHeader,
        BModal,
        BEmbed,
        TituloSeccion
    },
    directives: { 
        'b-modal': VBModal,
    },
    data(){
        return{
            titulo:'',
            items:[],
            video:'',
            title:'',
            url:'',
        }
    },
    metaInfo () {
        return {
        title: 'Ministerio Público',
        htmlAttrs: {
            lang: 'es-ES'
        },
        titleTemplate: '%s | '+this.titulo,
        meta: [
            { vmid: 'description', name: 'description', content: this.titulo }
        ]
        }
    },
    created: function () {   
        if (this.$route.params.slug) {
            this.listarVideos(this.$route.params.slug);
        }
    },
    watch: {
        $route: function (val) {
            if (val.params.slug) {
                this.listarVideos(this.$route.params.slug);
            }
        },
    },

    methods: {
        onkey(event){
            alert(event)
        },
        listarVideos(slug) {
            var url= '/admin/playlist/'+ slug;
            axiosIns.get(url)
            .then(res => {
                this.titulo = res.data.title;
                this.items = res.data.play_list_item;
            })
            .catch(err =>{
                console.log(err);
            });
        },
        selectVideo(data){
            this.title=data.title;
            if (data.opcion_id!=3) {
                this.video = `https://www.youtube.com/embed/${data.videoId}`;
            }else{
                this.video=data.videoId;
            }
        },
  },

}
</script>

<style >
.cursor{
    cursor: pointer;
}
.ratio{
    border-radius: 0;
}
</style>
